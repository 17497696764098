
export default {
    props: {
        name: {
            type: String,
            required: false,
            default: undefined,
        },
        label: {
            type: String,
            required: false,
            default: undefined,
        },
        options: {
            type: Array,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            open: false,
            selected: '',
        };
    },
    computed: {
        selectedLabel() {
            try {
                const result = this.options.find((item) => item.name === this.selected);

                return result ? result.label : null;
            } catch (e) {
                return null;
            }
        },
    },
    methods: {
        onChange(e) {
            const model = this.options.find((item) => item.name === this.selected);
            this.$emit('change', model);
        },
        selectOption(option) {
            this.selected = option.name;
            this.open = false;
            this.$emit('change', option);
        },
    },
};
