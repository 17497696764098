
export default {
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: undefined,
        },
        labelSr: {
            type: String,
            required: false,
            default: undefined,
        },
        icon: {
            type: String,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            content: this.value,
            error: false,
        };
    },
    methods: {
        handleInput() {
            this.$el.classList.remove('[&_.input]:border-error');
            this.$el.classList.remove('[&_.error]:flex');
            this.$el.classList.remove('focus:[&_.input]:ring-error');
        },
    },
    render(h) {
        const inputAttrs = this.$attrs;
        inputAttrs.value = this.content;
        inputAttrs.id = this.$attrs.name;
        if (!inputAttrs.type) {
            inputAttrs.type = 'text';
        }

        return h('div', [
            this.label
                ? h(
                      'OpusLabel',
                      {
                          class: {
                              'sr-only': this.labelSr,
                              'after:content-["*"] after:ml-0.5 after:text-red-500': this.$attrs.required === '',
                              'cursor-pointer': true,
                          },
                          attrs: {
                              for: this.$attrs.name,
                          },
                      },
                      this.label,
                  )
                : null,
            h('div', { class: { relative: true } }, [
                h('OpusInput', {
                    ref: 'input',
                    attrs: inputAttrs,
                    data: {
                        content: this.value,
                    },
                    class: {
                        'pl-12': this.icon,
                    },
                    on: {
                        input: (value) => {
                            this.$emit('input', value);
                        },
                        blur: (value) => {
                            this.$emit('blur', value);
                        },
                    },
                }),
                this.icon
                    ? h('OpusIcon', {
                          props: {
                              code: this.icon,
                              classNames: 'left-3 absolute top-1/2 transform -translate-y-1/2 text-gray-700',
                          },
                      })
                    : null,
            ]),
            h(
                'div',
                {
                    ref: 'error',
                    class: {
                        'error hidden text-error text-sm mt-1': true,
                    },
                    attrs: {
                        for: this.$attrs.name,
                    },
                },
                [
                    h('OpusIcon', {
                        props: {
                            code: 'exclamation',
                        },
                    }),
                    h('span', { class: { 'error-text my-auto ml-2': true } }),
                ],
            ),
        ]);
    },
};
